import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizQuestion1', 'quizQuestion2', 'quizQuestion3', 'quizQuestion4', 'quizQuestion5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "quiz"
    }}>{`Quiz`}</h1>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion1" once={true} question="QUESTION 01: I should step in and solve my teenager's problems so that they don't get too stressed." fallbackResponse={<p>
        The correct answer is: <b>False</b>. If parents always swoop in to fix their child's problems, their teen misses
        the opportunities to develop problem-solving skills themselves. So, the best way for you to help your teenager
        is to teach them skills and strategies so they can solve problems on their own. See{' '}
        <GoTo to="/m8-problems/02-deal-with-problems" mdxType="GoTo">Helping your teenager deal with problems</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion2" once={true} question="QUESTION 02: When helping my teenager to problem-solve, the first step is to clearly define the problem." fallbackResponse={<p>
        The correct answer is: <b>True</b>. The essential first step to problem solving is being clear about what the
        problem is. See <GoTo to="/m8-problems/03-problem-solving" mdxType="GoTo">1. Define the problem</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion3" once={true} question="QUESTION 03: After brainstorming possible solutions to a problem, my teenager should try to put each solution into action." fallbackResponse={<p>
        The correct answer is: <b>False</b>. Your teenager shouldn’t try to put every solution into action. With your
        help, your teenager should select the best solution or solutions and put those best ideas into action. See{' '}
        <GoTo to="/m8-problems/06-decide-solution" mdxType="GoTo">4. Decide on a solution</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion4" once={true} question="QUESTION 04: Complaints of headaches, stomach-aches or muscle pain can be signs of stress in a teenager." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Physical complaints can be a sign of stress in teenagers. It is important
        for you to pay attention to your teenager’s behaviour for signs of stress, especially if they occur regularly.
        See <GoTo to="/m8-problems/09-signs-of-stress" mdxType="GoTo">Spotting signs of stress</GoTo> for other possible signs of
        stress in teenagers.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion5" once={true} question="QUESTION 05: I should match my expectations for my teenager to their individual personality and capabilities." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Matching your expectations of your teenager to their unique personality and
        strengths helps you to have realistic expectations for your teenager, so that they are not placed under
        excessive parental pressure. See{' '}
        <GoTo to="/m8-problems/10-pressures-expectations" mdxType="GoTo">Managing pressures and expectations</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <SingleImage gridSize={4} smallGridSize={8} src="/images/shared/Oriental_quiz.svg" alt="thinking man" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      